import validate from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/filipsuska/Work/bs-app/middleware/auth.global.js";
import avatar_45global from "/Users/filipsuska/Work/bs-app/middleware/avatar.global.js";
import web_45push_45global from "/Users/filipsuska/Work/bs-app/middleware/web-push.global.js";
import manifest_45route_45rule from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  avatar_45global,
  web_45push_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}