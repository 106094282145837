export const useTheme = () => ({
  button: {
    default: `${buttonBase} border-gray-300 bg-white font-medium text-gray-700 focus:bg-gray-50`,
    alternative: `${buttonBase} bg-white border border-gray-200 focus:ring-gray-100`,
    energyOutline: `${buttonBase} bg-white text-energy border-energy focus:ring-confidence`,
    energy: `${buttonBase} bg-energy text-white border-energy focus:ring-confidence`,
  },
  input: {
    default: 'bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-confidence focus:border-confidence block w-full p-2.5',
  },
  box: {
    default: 'p-4 border rounded-lg shadow-md',
  }
});

const buttonBase = 'inline-flex items-center py-2.5 px-4 text-md font-medium rounded-lg focus:ring-2 border';
