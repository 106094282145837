import { useSessionStorage } from "@vueuse/core";
import { watch } from "vue";

export const useAuth = () => {
  const data = useState('auth:data', undefined);
  const token = useCookie('auth:key', {
    maxAge: 60 * 60 * 24 * 360,
  });

  const fetchUser = async () => {
    const url = new URL('/api/users/me', useRuntimeConfig().public.backendApiUrl);
    url.searchParams.append('auth', token.value);

    try {
      data.value = await $fetch(url, {method: "GET", headers: {'Content-Type': 'application/json'}});
    } catch (e) {
      if (e.status === 401) {
        data.value = null;
        token.value = null;
      } else {
        throw showError({
          statusCode: 500,
          statusMessage: "Chyba připojení. Zkuste to znovu."
        });
      }
    }
  };

  watch(token, async (v) => {
    if (!v) {
      data.value = null;
    }
  })

  return {
    init: async () => {
      if (!token.value) {
        data.value = null;

        return;
      }

      if (data.value !== undefined) {
        return data.value;
      }

      const user = useSessionStorage('auth:user', () => undefined);
      if (user.value) {
        data.value = user.value;

        return data.value;
      }

      await fetchUser();

      return data.value;
    },
    token: token,
    user: data,
    refresh: fetchUser,
  };
};
