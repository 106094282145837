<script setup lang="ts">
import {computed} from "vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

definePageMeta({
  layout: "raw",
});

const {button} = useTheme();

const error = useError();
const title = computed(() => {
  if (!error.value) return 'Neznámá chyba';

  if (error.value.statusCode === 404) return 'Stránka nenalezena';
  if (error.value.statusCode === 503) return 'Probíhá aktualizace aplikace';

  return error.value.statusCode === 500 ? 'Chyba připojení' : 'Neznámá chyba';
});

const onGoHome = () => {
  location.href = '/';
};

</script>

<template>
  <section class="bg-gray-50 h-screen">
    <div class="min-h-[100%] flex flex-col items-center justify-center px-6 py-8 mx-auto">
      <NuxtLink to="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
        <img src="/logo.png" class="h-20">
      </NuxtLink>
      <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md">
        <div class="p-12 space-y-6">
          <h1
            class="text-3xl text-center font-bold leading-tight tracking-tight text-confidence"
            v-text="title"
          ></h1>
          <button type="button" @click.once="onGoHome" :class="'w-full justify-center ' + button.energy">
            <FontAwesomeIcon class="mr-2" icon="fa-solid fa-home" />
            Domů
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
