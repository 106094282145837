import revive_payload_client_4sVQNw7RlN from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/filipsuska/Work/bs-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_328d8bab_BTtABaSOAY from "/Users/filipsuska/Work/bs-app/.nuxt/templates.pwa.client.328d8bab.ts";
import chunk_reload_client_UciE0i6zes from "/Users/filipsuska/Work/bs-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_client_WDNVdfLkAG from "/Users/filipsuska/Work/bs-app/plugins/01.sentry.client.js";
import clearSessionStorage_DFMT7NE79E from "/Users/filipsuska/Work/bs-app/plugins/clearSessionStorage.js";
import fontawesome_klhsrycjcK from "/Users/filipsuska/Work/bs-app/plugins/fontawesome.js";
import maska_UHaKf2z1iQ from "/Users/filipsuska/Work/bs-app/plugins/maska.ts";
import toats_WCVsxZjanV from "/Users/filipsuska/Work/bs-app/plugins/toats.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  templates_pwa_client_328d8bab_BTtABaSOAY,
  chunk_reload_client_UciE0i6zes,
  _01_sentry_client_WDNVdfLkAG,
  clearSessionStorage_DFMT7NE79E,
  fontawesome_klhsrycjcK,
  maska_UHaKf2z1iQ,
  toats_WCVsxZjanV
]