import {
  HttpClient as HttpClientIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import { withScope } from '@sentry/vue';
import { defineNuxtPlugin } from '#app';

// https://github.com/nuxt-community/sentry-module/issues/530#issuecomment-1570407408
export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    if (
      typeof window === 'undefined' ||
      !['true', true].includes(nuxtApp.$config.public.sentry.enabled)
    ) {
      return {
        provide: {
          sentrySetContext: (_name, _context) => {},
          sentrySetUser: (_user) => {},
          sentrySetTag: (_key, _value) => {},
          sentryAddBreadcrumb: (_breadcrumb) => {},
          sentryCaptureException: (_exception, _captureContext) => {},
        },
      };
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.sentry.dns,
      release: nuxtApp.$config.public.sentry.release,
      // environment: nuxtApp.$config.public.sentry.environment,
      integrations: [
        new Sentry.BrowserTracing({
          // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router, {
          routeLabel: 'path',
        }),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [nuxtApp.$config.public.hostName],
      }),
        new HttpClientIntegration(),
        new ReportingObserverIntegration(),
      ],
      trackComponents: true,
      hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      tracesSampleRate: 0.01,
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1,
    });

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context)
        Sentry.captureException(err)
      })
    };

    nuxtApp.hook('app:error', (err) => {
      Sentry.captureException(err)
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  },
})
