export const useBackend = () => ({
  get: (uri, throws = true) => exec("GET", uri, '', throws),
  post: (uri, body = {}, throws = true) => exec("POST", uri, body, throws),
  put: (uri, body = {}, throws = true) => exec("PUT", uri, body, throws),
  form: (uri, formdata, throws = true) => {
    const url = new URL(uri, useRuntimeConfig().public.backendApiUrl);

    const { token } = useAuth();
    if (token.value) url.searchParams.append('auth', token.value);

    const options = {
      method: 'POST',
      body: formdata,
    };

    return $fetch(url, options).catch((error) => {
      if (!throws) return false;

      console.error(error);
      throw showError({
        statusCode: 500,
        statusMessage: "Chyba připojení. Zkuste to znovu."
      });
    });
  },
  remove: (uri, body, showError = true) => exec("DELETE", uri, body, showError),
});

const exec = (method, uri, body = '', throws = true) => {
  const url = new URL(uri, useRuntimeConfig().public.backendApiUrl);

  const { token } = useAuth();
  if (token.value) url.searchParams.append('auth', token.value);

  const options = {
      method: method,
      headers: {
          'Content-Type': 'application/json',
      },
  };

  if (body !== '') {
      options.body = JSON.stringify(body);
  }

  return $fetch(url, options).catch((error) => {
    if (!throws) return false;

    console.error(error);
    throw showError({
      statusCode: 500,
      statusMessage: "Chyba připojení. Zkuste to znovu."
    });
  });
}
