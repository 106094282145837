import { default as affirmationMxAmdlPdSXMeta } from "/Users/filipsuska/Work/bs-app/pages/affirmation.vue?macro=true";
import { default as cartc2bux0pOq2Meta } from "/Users/filipsuska/Work/bs-app/pages/cart.vue?macro=true";
import { default as communityNbybEhvGS6Meta } from "/Users/filipsuska/Work/bs-app/pages/community.vue?macro=true";
import { default as _91id_93GPFgsiQZiPMeta } from "/Users/filipsuska/Work/bs-app/pages/courses/[id].vue?macro=true";
import { default as indexX3Ul4qZsYAMeta } from "/Users/filipsuska/Work/bs-app/pages/courses/index.vue?macro=true";
import { default as indexxiEAVbFanGMeta } from "/Users/filipsuska/Work/bs-app/pages/index.vue?macro=true";
import { default as _91course_93_45_91id_93_45questionsZTgtesYlLLMeta } from "/Users/filipsuska/Work/bs-app/pages/lessons/[course]-[id]-questions.vue?macro=true";
import { default as _91course_93_45_91id_93weOIkivwDCMeta } from "/Users/filipsuska/Work/bs-app/pages/lessons/[course]-[id].vue?macro=true";
import { default as loginXiK6PXmt9rMeta } from "/Users/filipsuska/Work/bs-app/pages/login.vue?macro=true";
import { default as meditationsLoQEiS3H82Meta } from "/Users/filipsuska/Work/bs-app/pages/meditations.vue?macro=true";
import { default as payment_45resultYgrBqJSYuOMeta } from "/Users/filipsuska/Work/bs-app/pages/payment-result.vue?macro=true";
import { default as registerdnxb3dAKqOMeta } from "/Users/filipsuska/Work/bs-app/pages/register.vue?macro=true";
import { default as tosuDNSGUTy2MMeta } from "/Users/filipsuska/Work/bs-app/pages/tos.vue?macro=true";
export default [
  {
    name: affirmationMxAmdlPdSXMeta?.name ?? "affirmation",
    path: affirmationMxAmdlPdSXMeta?.path ?? "/affirmation",
    meta: affirmationMxAmdlPdSXMeta || {},
    alias: affirmationMxAmdlPdSXMeta?.alias || [],
    redirect: affirmationMxAmdlPdSXMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/affirmation.vue").then(m => m.default || m)
  },
  {
    name: cartc2bux0pOq2Meta?.name ?? "cart",
    path: cartc2bux0pOq2Meta?.path ?? "/cart",
    meta: cartc2bux0pOq2Meta || {},
    alias: cartc2bux0pOq2Meta?.alias || [],
    redirect: cartc2bux0pOq2Meta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: communityNbybEhvGS6Meta?.name ?? "community",
    path: communityNbybEhvGS6Meta?.path ?? "/community",
    meta: communityNbybEhvGS6Meta || {},
    alias: communityNbybEhvGS6Meta?.alias || [],
    redirect: communityNbybEhvGS6Meta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/community.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GPFgsiQZiPMeta?.name ?? "courses-id",
    path: _91id_93GPFgsiQZiPMeta?.path ?? "/courses/:id()",
    meta: _91id_93GPFgsiQZiPMeta || {},
    alias: _91id_93GPFgsiQZiPMeta?.alias || [],
    redirect: _91id_93GPFgsiQZiPMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/courses/[id].vue").then(m => m.default || m)
  },
  {
    name: indexX3Ul4qZsYAMeta?.name ?? "courses",
    path: indexX3Ul4qZsYAMeta?.path ?? "/courses",
    meta: indexX3Ul4qZsYAMeta || {},
    alias: indexX3Ul4qZsYAMeta?.alias || [],
    redirect: indexX3Ul4qZsYAMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/courses/index.vue").then(m => m.default || m)
  },
  {
    name: indexxiEAVbFanGMeta?.name ?? "index",
    path: indexxiEAVbFanGMeta?.path ?? "/",
    meta: indexxiEAVbFanGMeta || {},
    alias: indexxiEAVbFanGMeta?.alias || [],
    redirect: indexxiEAVbFanGMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91course_93_45_91id_93_45questionsZTgtesYlLLMeta?.name ?? "lessons-course-id-questions",
    path: _91course_93_45_91id_93_45questionsZTgtesYlLLMeta?.path ?? "/lessons/:course()-:id()-questions",
    meta: _91course_93_45_91id_93_45questionsZTgtesYlLLMeta || {},
    alias: _91course_93_45_91id_93_45questionsZTgtesYlLLMeta?.alias || [],
    redirect: _91course_93_45_91id_93_45questionsZTgtesYlLLMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/lessons/[course]-[id]-questions.vue").then(m => m.default || m)
  },
  {
    name: _91course_93_45_91id_93weOIkivwDCMeta?.name ?? "lessons-course-id",
    path: _91course_93_45_91id_93weOIkivwDCMeta?.path ?? "/lessons/:course()-:id()",
    meta: _91course_93_45_91id_93weOIkivwDCMeta || {},
    alias: _91course_93_45_91id_93weOIkivwDCMeta?.alias || [],
    redirect: _91course_93_45_91id_93weOIkivwDCMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/lessons/[course]-[id].vue").then(m => m.default || m)
  },
  {
    name: loginXiK6PXmt9rMeta?.name ?? "login",
    path: loginXiK6PXmt9rMeta?.path ?? "/login",
    meta: loginXiK6PXmt9rMeta || {},
    alias: loginXiK6PXmt9rMeta?.alias || [],
    redirect: loginXiK6PXmt9rMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: meditationsLoQEiS3H82Meta?.name ?? "meditations",
    path: meditationsLoQEiS3H82Meta?.path ?? "/meditations",
    meta: meditationsLoQEiS3H82Meta || {},
    alias: meditationsLoQEiS3H82Meta?.alias || [],
    redirect: meditationsLoQEiS3H82Meta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/meditations.vue").then(m => m.default || m)
  },
  {
    name: payment_45resultYgrBqJSYuOMeta?.name ?? "payment-result",
    path: payment_45resultYgrBqJSYuOMeta?.path ?? "/payment-result",
    meta: payment_45resultYgrBqJSYuOMeta || {},
    alias: payment_45resultYgrBqJSYuOMeta?.alias || [],
    redirect: payment_45resultYgrBqJSYuOMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/payment-result.vue").then(m => m.default || m)
  },
  {
    name: registerdnxb3dAKqOMeta?.name ?? "register",
    path: registerdnxb3dAKqOMeta?.path ?? "/register",
    meta: registerdnxb3dAKqOMeta || {},
    alias: registerdnxb3dAKqOMeta?.alias || [],
    redirect: registerdnxb3dAKqOMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: tosuDNSGUTy2MMeta?.name ?? "tos",
    path: tosuDNSGUTy2MMeta?.path ?? "/tos",
    meta: tosuDNSGUTy2MMeta || {},
    alias: tosuDNSGUTy2MMeta?.alias || [],
    redirect: tosuDNSGUTy2MMeta?.redirect,
    component: () => import("/Users/filipsuska/Work/bs-app/pages/tos.vue").then(m => m.default || m)
  }
]