import { ref, readonly } from "vue";
import { useLocalStorage } from '@vueuse/core'

export const useWebPush = () => {
  const publicKey = useRuntimeConfig().public.pushVapidKey;
  const isCompatible = ref('serviceWorker' in navigator && 'PushManager' in window && 'showNotification' in ServiceWorkerRegistration.prototype);
  const permission = ref('denied');
  const backendInformedOf = useLocalStorage('webPush:backendInformedOf', null);
  const { post } = useBackend();

  setTimeout(() => {
    permission.value = window.Notification?.permission || 'denied';
  }, 1000);

  const subscribe = async () => {
    const sw = await navigator.serviceWorker.ready;

    try {
      const subscription = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicKey),
      });

      await post('/api/resource/webPush:createDevice', { subscription });
    } finally {
      permission.value = Notification?.permission;
    }
  }

  const refresh = async () => {
    if (!isCompatible.value) return;

    const sw = await navigator.serviceWorker.ready;
    const subscription = await sw.pushManager.getSubscription();
    if (!subscription) return;

    if (backendInformedOf.value === subscription.endpoint) return;

    try {
      await post('/api/resource/webPush:createDevice', { subscription }, false);
      backendInformedOf.value = subscription.endpoint;
    } catch (e) {
      //nothing
    }
  }

  return {
    subscribe,
    refresh,
    isCompatible: readonly(isCompatible),
    permission: readonly(permission),
  };
};

const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}
