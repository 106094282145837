export default defineNuxtRouteMiddleware((to) => {
  if (to.path === '/') {
    return;
  }

  const { user } = useAuth();
  if (user.value && !user.value.avatar) {
    return navigateTo('/');
  }
});
